.chatbot-jordy-icon-left {
  cursor: pointer;
  top: 85%;
  left: 1%;
  bottom: 18%;
}

.chatbot-jordy-icon-right {
  bottom: 0%;
  right: 0;
  cursor: pointer;
}

.minimize-image {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  height: 50px;
  margin-top: 15px;
  margin-left: -66px;
  width: 50px;
}

.background-jordy-image {
  width: 90px;
  height: 90px;
}

.jordy-chat-icon {
  margin-left: 10px;
  margin-top: 5px;
  width: 21px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #006BB6;
  margin-top: 5px;
}

.user-chat-icon {
  margin-right: 5px;
  width: 21px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #006BB6;
}

.no-image {
  cursor: pointer;
}

.cross-mark {
  position: absolute;
  top: 0px;
  width: 17px;
  height: 17px;
  left: 5px;
  right: 0;
  bottom: 5px;
  margin: auto;
  cursor: pointer;
}

.header-div {
  background-image: url('https://doubt-buster-images.s3.ap-south-1.amazonaws.com/jordyheader.png');
  width: 360px;
  height: 167px;
}

.header-div-mobile {
  background-image: url('https://doubt-buster-images.s3.ap-south-1.amazonaws.com/jordyheader.png');
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
}

.intro-div {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #006BB6;
  text-align: left;
}

.jordy-desc1 {
  width: 162px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #707070;
  text-align: left;
}

.chat-bot-app {
  position: fixed;
  bottom: 0px;
}

.chat-bot-app-mobile {
  position: unset;
  height: 100vh;
}

.minimize {
  text-align: right;
  padding-right: 5px;
  padding-top: 5px;
  cursor: pointer;
}

.message-inner-container {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.user-message-inner-container {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 10px;
}

.multiple-messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.scroll-bar {
  height: 345px;
}

.scroll-bar-mobile {
  height: 100%;
}

.messages {
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-container {
  width: 100%;
  display: inline-block;
}

.bot-message {
  float: left;
  padding: 8px 12px;
  margin: 3px;
  border-radius: 8px 8px 8px 0px;
  background: #DBF0FF;
  min-width: 40px;
  text-align: left;
  font-size: 12px;
  line-height: 15px;
  font-style: normal;
  font-weight: 400;
  word-break: break-word;
  margin-bottom: 5px;
}

.code-message {
  float: left;
  font-size: 12px;
  line-height: 15px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 12px;
  margin: 5px;
  border-radius: 8px 8px 8px 0px;
  background: #DBF0FF !important;
  min-width: 40px;
  text-align: left;
  overflow-x: hidden !important;
}

.user-message {
  float: right;
  padding: 8px 12px;
  border-radius: 8px 8px 0px 8px;
  background: #E9EBED;
  color: #1A1A1A;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin:0 5px;
  word-break: break-word;
}

.input {
  box-sizing: border-box;
  position: absolute;
  width: 360px;
  height: 40px;
  left: 0;
  bottom: 0;
  background: #F7F7F7;
  border-top: 1px solid #CCCCCC;
  border-radius: 0px 0px 10px 10px;
}

.input>form>input[type="text"] {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  background: #F7F7F7;
  color: black;
  border: 0;
  border-top: 1px solid #eee;
  outline: none;
  padding: 13px 25px 16px 10px;
  width: 100%;
  -webkit-appearance: none;
}

.input>form>input:focus {
  outline: none;
}

.input>form>button {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
}

.help-query{
  position: absolute;
  width: 79px;
  height: 18px;
  left: 12px;
  top: -13px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #006BB6;
  visibility: hidden;
}

.chatbot-jordy-icon-left:hover .help-query {
  visibility:visible;
}

.arrow-icon {
  margin-right: 5px;
}

.upload-icon{
  width: 30px;
  cursor: pointer;
}

.assistant-accept-block {
  margin-top: 7px;
}

.accept-button {
  float: left;
  text-align: center;
  padding: 7px;
  color: white;
  cursor: pointer;
  margin: 5px;
  width: 58px;
  background-color: #006BB6;
  border-radius: 7%;
}

.reject-button {
  float: left;
  text-align: center;
  padding: 7px;
  color: white;
  cursor: pointer;
  margin: 5px;
  width: 58px;
  background-color:  #a2a3a4;
  border-radius: 7%;
}

.connected-assitant-text {
  color: rgb(44, 93, 203);
  padding: 15px;
  font-size: 13px;
}

.executives-busy-message {
  padding: 25px;
}

.connect-to-expert-text {
  font-size: 13px;
  margin-top: 10px;
  color: #5A5A5A;
  margin-bottom: 10px;
}

.trainer-name-text {
  color: #5A5A5A;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #5A5A5A;
  line-height: 0.01em;
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.trainer-name-text span {
  background: #fff;
  padding: 0 10px;
}

.header-sub-div {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 40px;
}

.form-block {
  display: flex;
  align-items: center;
  width: 100%;
  background: #F7F7F7;
}

.upload-file {
  display: none;
}

.file-style {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.intent-data {
  position: fixed;
  top: 375px;
  left: 450px;
}

.intent-data table, .intent-data th, .intent-data td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

.hoverButtons {
  width: 88.8%; 
  display: flex;
  justify-content: flex-start;
  padding-left: 2.38em;
  bottom: -1em;
  position: absolute;
}

.selectedReaction {
  position: absolute;
  left: 2.4em;
  top: -0.8em;
}
